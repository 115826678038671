
/**
 * @name: 数据统计-外链跳转次数统计
 * @author: 
 * @date: 2024-05-13 11:37
 * @description： 数据统计-外链跳转次数统计
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import type { ICrudOption } from "@/types/m-ui-crud"
  
@Component({})
export default class statisticsNav extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: []
  }

  getList () {

  }

  created () {
    this.getList()
  }
}
